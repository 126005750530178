import * as L from "react";
import { useLayoutEffect as Bt, useEffect as Ht } from "react";
import * as Wt from "react-dom";
const Nt = ["top", "right", "bottom", "left"], j = Math.min, P = Math.max, nt = Math.round, tt = Math.floor, $ = (t) => ({
  x: t,
  y: t
}), Vt = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, It = {
  start: "end",
  end: "start"
};
function ft(t, e, n) {
  return P(t, j(e, n));
}
function N(t, e) {
  return typeof t == "function" ? t(e) : t;
}
function V(t) {
  return t.split("-")[0];
}
function G(t) {
  return t.split("-")[1];
}
function ut(t) {
  return t === "x" ? "y" : "x";
}
function dt(t) {
  return t === "y" ? "height" : "width";
}
function J(t) {
  return ["top", "bottom"].includes(V(t)) ? "y" : "x";
}
function mt(t) {
  return ut(J(t));
}
function zt(t, e, n) {
  n === void 0 && (n = !1);
  const o = G(t), i = mt(t), r = dt(i);
  let s = i === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return e.reference[r] > e.floating[r] && (s = ot(s)), [s, ot(s)];
}
function jt(t) {
  const e = ot(t);
  return [at(t), e, at(e)];
}
function at(t) {
  return t.replace(/start|end/g, (e) => It[e]);
}
function $t(t, e, n) {
  const o = ["left", "right"], i = ["right", "left"], r = ["top", "bottom"], s = ["bottom", "top"];
  switch (t) {
    case "top":
    case "bottom":
      return n ? e ? i : o : e ? o : i;
    case "left":
    case "right":
      return e ? r : s;
    default:
      return [];
  }
}
function _t(t, e, n, o) {
  const i = G(t);
  let r = $t(V(t), n === "start", o);
  return i && (r = r.map((s) => s + "-" + i), e && (r = r.concat(r.map(at)))), r;
}
function ot(t) {
  return t.replace(/left|right|bottom|top/g, (e) => Vt[e]);
}
function Xt(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function Et(t) {
  return typeof t != "number" ? Xt(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function it(t) {
  return {
    ...t,
    top: t.y,
    left: t.x,
    right: t.x + t.width,
    bottom: t.y + t.height
  };
}
function xt(t, e, n) {
  let {
    reference: o,
    floating: i
  } = t;
  const r = J(e), s = mt(e), l = dt(s), c = V(e), f = r === "y", m = o.x + o.width / 2 - i.width / 2, u = o.y + o.height / 2 - i.height / 2, d = o[l] / 2 - i[l] / 2;
  let a;
  switch (c) {
    case "top":
      a = {
        x: m,
        y: o.y - i.height
      };
      break;
    case "bottom":
      a = {
        x: m,
        y: o.y + o.height
      };
      break;
    case "right":
      a = {
        x: o.x + o.width,
        y: u
      };
      break;
    case "left":
      a = {
        x: o.x - i.width,
        y: u
      };
      break;
    default:
      a = {
        x: o.x,
        y: o.y
      };
  }
  switch (G(e)) {
    case "start":
      a[s] -= d * (n && f ? -1 : 1);
      break;
    case "end":
      a[s] += d * (n && f ? -1 : 1);
      break;
  }
  return a;
}
const Yt = async (t, e, n) => {
  const {
    placement: o = "bottom",
    strategy: i = "absolute",
    middleware: r = [],
    platform: s
  } = n, l = r.filter(Boolean), c = await (s.isRTL == null ? void 0 : s.isRTL(e));
  let f = await s.getElementRects({
    reference: t,
    floating: e,
    strategy: i
  }), {
    x: m,
    y: u
  } = xt(f, o, c), d = o, a = {}, g = 0;
  for (let p = 0; p < l.length; p++) {
    const {
      name: y,
      fn: h
    } = l[p], {
      x: w,
      y: v,
      data: b,
      reset: x
    } = await h({
      x: m,
      y: u,
      initialPlacement: o,
      placement: d,
      strategy: i,
      middlewareData: a,
      rects: f,
      platform: s,
      elements: {
        reference: t,
        floating: e
      }
    });
    if (m = w ?? m, u = v ?? u, a = {
      ...a,
      [y]: {
        ...a[y],
        ...b
      }
    }, x && g <= 50) {
      g++, typeof x == "object" && (x.placement && (d = x.placement), x.rects && (f = x.rects === !0 ? await s.getElementRects({
        reference: t,
        floating: e,
        strategy: i
      }) : x.rects), {
        x: m,
        y: u
      } = xt(f, d, c)), p = -1;
      continue;
    }
  }
  return {
    x: m,
    y: u,
    placement: d,
    strategy: i,
    middlewareData: a
  };
};
async function Q(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: o,
    y: i,
    platform: r,
    rects: s,
    elements: l,
    strategy: c
  } = t, {
    boundary: f = "clippingAncestors",
    rootBoundary: m = "viewport",
    elementContext: u = "floating",
    altBoundary: d = !1,
    padding: a = 0
  } = N(e, t), g = Et(a), y = l[d ? u === "floating" ? "reference" : "floating" : u], h = it(await r.getClippingRect({
    element: (n = await (r.isElement == null ? void 0 : r.isElement(y))) == null || n ? y : y.contextElement || await (r.getDocumentElement == null ? void 0 : r.getDocumentElement(l.floating)),
    boundary: f,
    rootBoundary: m,
    strategy: c
  })), w = u === "floating" ? {
    ...s.floating,
    x: o,
    y: i
  } : s.reference, v = await (r.getOffsetParent == null ? void 0 : r.getOffsetParent(l.floating)), b = await (r.isElement == null ? void 0 : r.isElement(v)) ? await (r.getScale == null ? void 0 : r.getScale(v)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, x = it(r.convertOffsetParentRelativeRectToViewportRelativeRect ? await r.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: w,
    offsetParent: v,
    strategy: c
  }) : w);
  return {
    top: (h.top - x.top + g.top) / b.y,
    bottom: (x.bottom - h.bottom + g.bottom) / b.y,
    left: (h.left - x.left + g.left) / b.x,
    right: (x.right - h.right + g.right) / b.x
  };
}
const wt = (t) => ({
  name: "arrow",
  options: t,
  async fn(e) {
    const {
      x: n,
      y: o,
      placement: i,
      rects: r,
      platform: s,
      elements: l
    } = e, {
      element: c,
      padding: f = 0
    } = N(t, e) || {};
    if (c == null)
      return {};
    const m = Et(f), u = {
      x: n,
      y: o
    }, d = mt(i), a = dt(d), g = await s.getDimensions(c), p = d === "y", y = p ? "top" : "left", h = p ? "bottom" : "right", w = p ? "clientHeight" : "clientWidth", v = r.reference[a] + r.reference[d] - u[d] - r.floating[a], b = u[d] - r.reference[d], x = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(c));
    let A = x ? x[w] : 0;
    (!A || !await (s.isElement == null ? void 0 : s.isElement(x))) && (A = l.floating[w] || r.floating[a]);
    const E = v / 2 - b / 2, T = A / 2 - g[a] / 2 - 1, k = j(m[y], T), H = j(m[h], T), O = k, W = A - g[a] - H, S = A / 2 - g[a] / 2 + E, C = ft(O, S, W), R = G(i) != null && S != C && r.reference[a] / 2 - (S < O ? k : H) - g[a] / 2 < 0 ? S < O ? O - S : W - S : 0;
    return {
      [d]: u[d] - R,
      data: {
        [d]: C,
        centerOffset: S - C + R
      }
    };
  }
}), me = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n;
      const {
        placement: o,
        middlewareData: i,
        rects: r,
        initialPlacement: s,
        platform: l,
        elements: c
      } = e, {
        mainAxis: f = !0,
        crossAxis: m = !0,
        fallbackPlacements: u,
        fallbackStrategy: d = "bestFit",
        fallbackAxisSideDirection: a = "none",
        flipAlignment: g = !0,
        ...p
      } = N(t, e), y = V(o), h = V(s) === s, w = await (l.isRTL == null ? void 0 : l.isRTL(c.floating)), v = u || (h || !g ? [ot(s)] : jt(s));
      !u && a !== "none" && v.push(..._t(s, g, a, w));
      const b = [s, ...v], x = await Q(e, p), A = [];
      let E = ((n = i.flip) == null ? void 0 : n.overflows) || [];
      if (f && A.push(x[y]), m) {
        const O = zt(o, r, w);
        A.push(x[O[0]], x[O[1]]);
      }
      if (E = [...E, {
        placement: o,
        overflows: A
      }], !A.every((O) => O <= 0)) {
        var T, k;
        const O = (((T = i.flip) == null ? void 0 : T.index) || 0) + 1, W = b[O];
        if (W)
          return {
            data: {
              index: O,
              overflows: E
            },
            reset: {
              placement: W
            }
          };
        let S = (k = E.filter((C) => C.overflows[0] <= 0).sort((C, F) => C.overflows[1] - F.overflows[1])[0]) == null ? void 0 : k.placement;
        if (!S)
          switch (d) {
            case "bestFit": {
              var H;
              const C = (H = E.map((F) => [F.placement, F.overflows.filter((R) => R > 0).reduce((R, Y) => R + Y, 0)]).sort((F, R) => F[1] - R[1])[0]) == null ? void 0 : H[0];
              C && (S = C);
              break;
            }
            case "initialPlacement":
              S = s;
              break;
          }
        if (o !== S)
          return {
            reset: {
              placement: S
            }
          };
      }
      return {};
    }
  };
};
function yt(t, e) {
  return {
    top: t.top - e.height,
    right: t.right - e.width,
    bottom: t.bottom - e.height,
    left: t.left - e.width
  };
}
function vt(t) {
  return Nt.some((e) => t[e] >= 0);
}
const ge = function(t) {
  return t === void 0 && (t = {}), {
    name: "hide",
    options: t,
    async fn(e) {
      const {
        rects: n
      } = e, {
        strategy: o = "referenceHidden",
        ...i
      } = N(t, e);
      switch (o) {
        case "referenceHidden": {
          const r = await Q(e, {
            ...i,
            elementContext: "reference"
          }), s = yt(r, n.reference);
          return {
            data: {
              referenceHiddenOffsets: s,
              referenceHidden: vt(s)
            }
          };
        }
        case "escaped": {
          const r = await Q(e, {
            ...i,
            altBoundary: !0
          }), s = yt(r, n.floating);
          return {
            data: {
              escapedOffsets: s,
              escaped: vt(s)
            }
          };
        }
        default:
          return {};
      }
    }
  };
};
async function qt(t, e) {
  const {
    placement: n,
    platform: o,
    elements: i
  } = t, r = await (o.isRTL == null ? void 0 : o.isRTL(i.floating)), s = V(n), l = G(n), c = J(n) === "y", f = ["left", "top"].includes(s) ? -1 : 1, m = r && c ? -1 : 1, u = N(e, t);
  let {
    mainAxis: d,
    crossAxis: a,
    alignmentAxis: g
  } = typeof u == "number" ? {
    mainAxis: u,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...u
  };
  return l && typeof g == "number" && (a = l === "end" ? g * -1 : g), c ? {
    x: a * m,
    y: d * f
  } : {
    x: d * f,
    y: a * m
  };
}
const he = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o
      } = e, i = await qt(e, t);
      return {
        x: n + i.x,
        y: o + i.y,
        data: i
      };
    }
  };
}, pe = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o,
        placement: i
      } = e, {
        mainAxis: r = !0,
        crossAxis: s = !1,
        limiter: l = {
          fn: (y) => {
            let {
              x: h,
              y: w
            } = y;
            return {
              x: h,
              y: w
            };
          }
        },
        ...c
      } = N(t, e), f = {
        x: n,
        y: o
      }, m = await Q(e, c), u = J(V(i)), d = ut(u);
      let a = f[d], g = f[u];
      if (r) {
        const y = d === "y" ? "top" : "left", h = d === "y" ? "bottom" : "right", w = a + m[y], v = a - m[h];
        a = ft(w, a, v);
      }
      if (s) {
        const y = u === "y" ? "top" : "left", h = u === "y" ? "bottom" : "right", w = g + m[y], v = g - m[h];
        g = ft(w, g, v);
      }
      const p = l.fn({
        ...e,
        [d]: a,
        [u]: g
      });
      return {
        ...p,
        data: {
          x: p.x - n,
          y: p.y - o
        }
      };
    }
  };
}, xe = function(t) {
  return t === void 0 && (t = {}), {
    options: t,
    fn(e) {
      const {
        x: n,
        y: o,
        placement: i,
        rects: r,
        middlewareData: s
      } = e, {
        offset: l = 0,
        mainAxis: c = !0,
        crossAxis: f = !0
      } = N(t, e), m = {
        x: n,
        y: o
      }, u = J(i), d = ut(u);
      let a = m[d], g = m[u];
      const p = N(l, e), y = typeof p == "number" ? {
        mainAxis: p,
        crossAxis: 0
      } : {
        mainAxis: 0,
        crossAxis: 0,
        ...p
      };
      if (c) {
        const v = d === "y" ? "height" : "width", b = r.reference[d] - r.floating[v] + y.mainAxis, x = r.reference[d] + r.reference[v] - y.mainAxis;
        a < b ? a = b : a > x && (a = x);
      }
      if (f) {
        var h, w;
        const v = d === "y" ? "width" : "height", b = ["top", "left"].includes(V(i)), x = r.reference[u] - r.floating[v] + (b && ((h = s.offset) == null ? void 0 : h[u]) || 0) + (b ? 0 : y.crossAxis), A = r.reference[u] + r.reference[v] + (b ? 0 : ((w = s.offset) == null ? void 0 : w[u]) || 0) - (b ? y.crossAxis : 0);
        g < x ? g = x : g > A && (g = A);
      }
      return {
        [d]: a,
        [u]: g
      };
    }
  };
}, we = function(t) {
  return t === void 0 && (t = {}), {
    name: "size",
    options: t,
    async fn(e) {
      const {
        placement: n,
        rects: o,
        platform: i,
        elements: r
      } = e, {
        apply: s = () => {
        },
        ...l
      } = N(t, e), c = await Q(e, l), f = V(n), m = G(n), u = J(n) === "y", {
        width: d,
        height: a
      } = o.floating;
      let g, p;
      f === "top" || f === "bottom" ? (g = f, p = m === (await (i.isRTL == null ? void 0 : i.isRTL(r.floating)) ? "start" : "end") ? "left" : "right") : (p = f, g = m === "end" ? "top" : "bottom");
      const y = a - c[g], h = d - c[p], w = !e.middlewareData.shift;
      let v = y, b = h;
      if (u) {
        const A = d - c.left - c.right;
        b = m || w ? j(h, A) : A;
      } else {
        const A = a - c.top - c.bottom;
        v = m || w ? j(y, A) : A;
      }
      if (w && !m) {
        const A = P(c.left, 0), E = P(c.right, 0), T = P(c.top, 0), k = P(c.bottom, 0);
        u ? b = d - 2 * (A !== 0 || E !== 0 ? A + E : P(c.left, c.right)) : v = a - 2 * (T !== 0 || k !== 0 ? T + k : P(c.top, c.bottom));
      }
      await s({
        ...e,
        availableWidth: b,
        availableHeight: v
      });
      const x = await i.getDimensions(r.floating);
      return d !== x.width || a !== x.height ? {
        reset: {
          rects: !0
        }
      } : {};
    }
  };
};
function _(t) {
  return St(t) ? (t.nodeName || "").toLowerCase() : "#document";
}
function M(t) {
  var e;
  return (t == null || (e = t.ownerDocument) == null ? void 0 : e.defaultView) || window;
}
function z(t) {
  var e;
  return (e = (St(t) ? t.ownerDocument : t.document) || window.document) == null ? void 0 : e.documentElement;
}
function St(t) {
  return t instanceof Node || t instanceof M(t).Node;
}
function I(t) {
  return t instanceof Element || t instanceof M(t).Element;
}
function B(t) {
  return t instanceof HTMLElement || t instanceof M(t).HTMLElement;
}
function bt(t) {
  return typeof ShadowRoot > "u" ? !1 : t instanceof ShadowRoot || t instanceof M(t).ShadowRoot;
}
function Z(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: o,
    display: i
  } = D(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + o + n) && !["inline", "contents"].includes(i);
}
function Ut(t) {
  return ["table", "td", "th"].includes(_(t));
}
function gt(t) {
  const e = ht(), n = D(t);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !e && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !e && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((o) => (n.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some((o) => (n.contain || "").includes(o));
}
function Kt(t) {
  let e = K(t);
  for (; B(e) && !ct(e); ) {
    if (gt(e))
      return e;
    e = K(e);
  }
  return null;
}
function ht() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function ct(t) {
  return ["html", "body", "#document"].includes(_(t));
}
function D(t) {
  return M(t).getComputedStyle(t);
}
function lt(t) {
  return I(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function K(t) {
  if (_(t) === "html")
    return t;
  const e = (
    // Step into the shadow DOM of the parent of a slotted node.
    t.assignedSlot || // DOM Element detected.
    t.parentNode || // ShadowRoot detected.
    bt(t) && t.host || // Fallback.
    z(t)
  );
  return bt(e) ? e.host : e;
}
function Lt(t) {
  const e = K(t);
  return ct(e) ? t.ownerDocument ? t.ownerDocument.body : t.body : B(e) && Z(e) ? e : Lt(e);
}
function rt(t, e) {
  var n;
  e === void 0 && (e = []);
  const o = Lt(t), i = o === ((n = t.ownerDocument) == null ? void 0 : n.body), r = M(o);
  return i ? e.concat(r, r.visualViewport || [], Z(o) ? o : []) : e.concat(o, rt(o));
}
function Tt(t) {
  const e = D(t);
  let n = parseFloat(e.width) || 0, o = parseFloat(e.height) || 0;
  const i = B(t), r = i ? t.offsetWidth : n, s = i ? t.offsetHeight : o, l = nt(n) !== r || nt(o) !== s;
  return l && (n = r, o = s), {
    width: n,
    height: o,
    $: l
  };
}
function pt(t) {
  return I(t) ? t : t.contextElement;
}
function U(t) {
  const e = pt(t);
  if (!B(e))
    return $(1);
  const n = e.getBoundingClientRect(), {
    width: o,
    height: i,
    $: r
  } = Tt(e);
  let s = (r ? nt(n.width) : n.width) / o, l = (r ? nt(n.height) : n.height) / i;
  return (!s || !Number.isFinite(s)) && (s = 1), (!l || !Number.isFinite(l)) && (l = 1), {
    x: s,
    y: l
  };
}
const Gt = /* @__PURE__ */ $(0);
function Pt(t) {
  const e = M(t);
  return !ht() || !e.visualViewport ? Gt : {
    x: e.visualViewport.offsetLeft,
    y: e.visualViewport.offsetTop
  };
}
function Jt(t, e, n) {
  return e === void 0 && (e = !1), !n || e && n !== M(t) ? !1 : e;
}
function X(t, e, n, o) {
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const i = t.getBoundingClientRect(), r = pt(t);
  let s = $(1);
  e && (o ? I(o) && (s = U(o)) : s = U(t));
  const l = Jt(r, n, o) ? Pt(r) : $(0);
  let c = (i.left + l.x) / s.x, f = (i.top + l.y) / s.y, m = i.width / s.x, u = i.height / s.y;
  if (r) {
    const d = M(r), a = o && I(o) ? M(o) : o;
    let g = d.frameElement;
    for (; g && o && a !== d; ) {
      const p = U(g), y = g.getBoundingClientRect(), h = D(g), w = y.left + (g.clientLeft + parseFloat(h.paddingLeft)) * p.x, v = y.top + (g.clientTop + parseFloat(h.paddingTop)) * p.y;
      c *= p.x, f *= p.y, m *= p.x, u *= p.y, c += w, f += v, g = M(g).frameElement;
    }
  }
  return it({
    width: m,
    height: u,
    x: c,
    y: f
  });
}
function Qt(t) {
  let {
    rect: e,
    offsetParent: n,
    strategy: o
  } = t;
  const i = B(n), r = z(n);
  if (n === r)
    return e;
  let s = {
    scrollLeft: 0,
    scrollTop: 0
  }, l = $(1);
  const c = $(0);
  if ((i || !i && o !== "fixed") && ((_(n) !== "body" || Z(r)) && (s = lt(n)), B(n))) {
    const f = X(n);
    l = U(n), c.x = f.x + n.clientLeft, c.y = f.y + n.clientTop;
  }
  return {
    width: e.width * l.x,
    height: e.height * l.y,
    x: e.x * l.x - s.scrollLeft * l.x + c.x,
    y: e.y * l.y - s.scrollTop * l.y + c.y
  };
}
function Zt(t) {
  return Array.from(t.getClientRects());
}
function Mt(t) {
  return X(z(t)).left + lt(t).scrollLeft;
}
function te(t) {
  const e = z(t), n = lt(t), o = t.ownerDocument.body, i = P(e.scrollWidth, e.clientWidth, o.scrollWidth, o.clientWidth), r = P(e.scrollHeight, e.clientHeight, o.scrollHeight, o.clientHeight);
  let s = -n.scrollLeft + Mt(t);
  const l = -n.scrollTop;
  return D(o).direction === "rtl" && (s += P(e.clientWidth, o.clientWidth) - i), {
    width: i,
    height: r,
    x: s,
    y: l
  };
}
function ee(t, e) {
  const n = M(t), o = z(t), i = n.visualViewport;
  let r = o.clientWidth, s = o.clientHeight, l = 0, c = 0;
  if (i) {
    r = i.width, s = i.height;
    const f = ht();
    (!f || f && e === "fixed") && (l = i.offsetLeft, c = i.offsetTop);
  }
  return {
    width: r,
    height: s,
    x: l,
    y: c
  };
}
function ne(t, e) {
  const n = X(t, !0, e === "fixed"), o = n.top + t.clientTop, i = n.left + t.clientLeft, r = B(t) ? U(t) : $(1), s = t.clientWidth * r.x, l = t.clientHeight * r.y, c = i * r.x, f = o * r.y;
  return {
    width: s,
    height: l,
    x: c,
    y: f
  };
}
function At(t, e, n) {
  let o;
  if (e === "viewport")
    o = ee(t, n);
  else if (e === "document")
    o = te(z(t));
  else if (I(e))
    o = ne(e, n);
  else {
    const i = Pt(t);
    o = {
      ...e,
      x: e.x - i.x,
      y: e.y - i.y
    };
  }
  return it(o);
}
function kt(t, e) {
  const n = K(t);
  return n === e || !I(n) || ct(n) ? !1 : D(n).position === "fixed" || kt(n, e);
}
function oe(t, e) {
  const n = e.get(t);
  if (n)
    return n;
  let o = rt(t).filter((l) => I(l) && _(l) !== "body"), i = null;
  const r = D(t).position === "fixed";
  let s = r ? K(t) : t;
  for (; I(s) && !ct(s); ) {
    const l = D(s), c = gt(s);
    !c && l.position === "fixed" && (i = null), (r ? !c && !i : !c && l.position === "static" && !!i && ["absolute", "fixed"].includes(i.position) || Z(s) && !c && kt(t, s)) ? o = o.filter((m) => m !== s) : i = l, s = K(s);
  }
  return e.set(t, o), o;
}
function ie(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: o,
    strategy: i
  } = t;
  const s = [...n === "clippingAncestors" ? oe(e, this._c) : [].concat(n), o], l = s[0], c = s.reduce((f, m) => {
    const u = At(e, m, i);
    return f.top = P(u.top, f.top), f.right = j(u.right, f.right), f.bottom = j(u.bottom, f.bottom), f.left = P(u.left, f.left), f;
  }, At(e, l, i));
  return {
    width: c.right - c.left,
    height: c.bottom - c.top,
    x: c.left,
    y: c.top
  };
}
function re(t) {
  return Tt(t);
}
function se(t, e, n) {
  const o = B(e), i = z(e), r = n === "fixed", s = X(t, !0, r, e);
  let l = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const c = $(0);
  if (o || !o && !r)
    if ((_(e) !== "body" || Z(i)) && (l = lt(e)), o) {
      const f = X(e, !0, r, e);
      c.x = f.x + e.clientLeft, c.y = f.y + e.clientTop;
    } else
      i && (c.x = Mt(i));
  return {
    x: s.left + l.scrollLeft - c.x,
    y: s.top + l.scrollTop - c.y,
    width: s.width,
    height: s.height
  };
}
function Rt(t, e) {
  return !B(t) || D(t).position === "fixed" ? null : e ? e(t) : t.offsetParent;
}
function Dt(t, e) {
  const n = M(t);
  if (!B(t))
    return n;
  let o = Rt(t, e);
  for (; o && Ut(o) && D(o).position === "static"; )
    o = Rt(o, e);
  return o && (_(o) === "html" || _(o) === "body" && D(o).position === "static" && !gt(o)) ? n : o || Kt(t) || n;
}
const ce = async function(t) {
  let {
    reference: e,
    floating: n,
    strategy: o
  } = t;
  const i = this.getOffsetParent || Dt, r = this.getDimensions;
  return {
    reference: se(e, await i(n), o),
    floating: {
      x: 0,
      y: 0,
      ...await r(n)
    }
  };
};
function le(t) {
  return D(t).direction === "rtl";
}
const fe = {
  convertOffsetParentRelativeRectToViewportRelativeRect: Qt,
  getDocumentElement: z,
  getClippingRect: ie,
  getOffsetParent: Dt,
  getElementRects: ce,
  getClientRects: Zt,
  getDimensions: re,
  getScale: U,
  isElement: I,
  isRTL: le
};
function ae(t, e) {
  let n = null, o;
  const i = z(t);
  function r() {
    clearTimeout(o), n && n.disconnect(), n = null;
  }
  function s(l, c) {
    l === void 0 && (l = !1), c === void 0 && (c = 1), r();
    const {
      left: f,
      top: m,
      width: u,
      height: d
    } = t.getBoundingClientRect();
    if (l || e(), !u || !d)
      return;
    const a = tt(m), g = tt(i.clientWidth - (f + u)), p = tt(i.clientHeight - (m + d)), y = tt(f), w = {
      rootMargin: -a + "px " + -g + "px " + -p + "px " + -y + "px",
      threshold: P(0, j(1, c)) || 1
    };
    let v = !0;
    function b(x) {
      const A = x[0].intersectionRatio;
      if (A !== c) {
        if (!v)
          return s();
        A ? s(!1, A) : o = setTimeout(() => {
          s(!1, 1e-7);
        }, 100);
      }
      v = !1;
    }
    try {
      n = new IntersectionObserver(b, {
        ...w,
        // Handle <iframe>s
        root: i.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(b, w);
    }
    n.observe(t);
  }
  return s(!0), r;
}
function ye(t, e, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: i = !0,
    ancestorResize: r = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: l = typeof IntersectionObserver == "function",
    animationFrame: c = !1
  } = o, f = pt(t), m = i || r ? [...f ? rt(f) : [], ...rt(e)] : [];
  m.forEach((h) => {
    i && h.addEventListener("scroll", n, {
      passive: !0
    }), r && h.addEventListener("resize", n);
  });
  const u = f && l ? ae(f, n) : null;
  let d = -1, a = null;
  s && (a = new ResizeObserver((h) => {
    let [w] = h;
    w && w.target === f && a && (a.unobserve(e), cancelAnimationFrame(d), d = requestAnimationFrame(() => {
      a && a.observe(e);
    })), n();
  }), f && !c && a.observe(f), a.observe(e));
  let g, p = c ? X(t) : null;
  c && y();
  function y() {
    const h = X(t);
    p && (h.x !== p.x || h.y !== p.y || h.width !== p.width || h.height !== p.height) && n(), p = h, g = requestAnimationFrame(y);
  }
  return n(), () => {
    m.forEach((h) => {
      i && h.removeEventListener("scroll", n), r && h.removeEventListener("resize", n);
    }), u && u(), a && a.disconnect(), a = null, c && cancelAnimationFrame(g);
  };
}
const ue = (t, e, n) => {
  const o = /* @__PURE__ */ new Map(), i = {
    platform: fe,
    ...n
  }, r = {
    ...i.platform,
    _c: o
  };
  return Yt(t, e, {
    ...i,
    platform: r
  });
}, ve = (t) => {
  function e(n) {
    return {}.hasOwnProperty.call(n, "current");
  }
  return {
    name: "arrow",
    options: t,
    fn(n) {
      const {
        element: o,
        padding: i
      } = typeof t == "function" ? t(n) : t;
      return o && e(o) ? o.current != null ? wt({
        element: o.current,
        padding: i
      }).fn(n) : {} : o ? wt({
        element: o,
        padding: i
      }).fn(n) : {};
    }
  };
};
var et = typeof document < "u" ? Bt : Ht;
function st(t, e) {
  if (t === e)
    return !0;
  if (typeof t != typeof e)
    return !1;
  if (typeof t == "function" && t.toString() === e.toString())
    return !0;
  let n, o, i;
  if (t && e && typeof t == "object") {
    if (Array.isArray(t)) {
      if (n = t.length, n != e.length)
        return !1;
      for (o = n; o-- !== 0; )
        if (!st(t[o], e[o]))
          return !1;
      return !0;
    }
    if (i = Object.keys(t), n = i.length, n !== Object.keys(e).length)
      return !1;
    for (o = n; o-- !== 0; )
      if (!{}.hasOwnProperty.call(e, i[o]))
        return !1;
    for (o = n; o-- !== 0; ) {
      const r = i[o];
      if (!(r === "_owner" && t.$$typeof) && !st(t[r], e[r]))
        return !1;
    }
    return !0;
  }
  return t !== t && e !== e;
}
function Ft(t) {
  return typeof window > "u" ? 1 : (t.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function Ot(t, e) {
  const n = Ft(t);
  return Math.round(e * n) / n;
}
function Ct(t) {
  const e = L.useRef(t);
  return et(() => {
    e.current = t;
  }), e;
}
function be(t) {
  t === void 0 && (t = {});
  const {
    placement: e = "bottom",
    strategy: n = "absolute",
    middleware: o = [],
    platform: i,
    elements: {
      reference: r,
      floating: s
    } = {},
    transform: l = !0,
    whileElementsMounted: c,
    open: f
  } = t, [m, u] = L.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: e,
    middlewareData: {},
    isPositioned: !1
  }), [d, a] = L.useState(o);
  st(d, o) || a(o);
  const [g, p] = L.useState(null), [y, h] = L.useState(null), w = L.useCallback((R) => {
    R != A.current && (A.current = R, p(R));
  }, [p]), v = L.useCallback((R) => {
    R !== E.current && (E.current = R, h(R));
  }, [h]), b = r || g, x = s || y, A = L.useRef(null), E = L.useRef(null), T = L.useRef(m), k = Ct(c), H = Ct(i), O = L.useCallback(() => {
    if (!A.current || !E.current)
      return;
    const R = {
      placement: e,
      strategy: n,
      middleware: d
    };
    H.current && (R.platform = H.current), ue(A.current, E.current, R).then((Y) => {
      const q = {
        ...Y,
        isPositioned: !0
      };
      W.current && !st(T.current, q) && (T.current = q, Wt.flushSync(() => {
        u(q);
      }));
    });
  }, [d, e, n, H]);
  et(() => {
    f === !1 && T.current.isPositioned && (T.current.isPositioned = !1, u((R) => ({
      ...R,
      isPositioned: !1
    })));
  }, [f]);
  const W = L.useRef(!1);
  et(() => (W.current = !0, () => {
    W.current = !1;
  }), []), et(() => {
    if (b && (A.current = b), x && (E.current = x), b && x) {
      if (k.current)
        return k.current(b, x, O);
      O();
    }
  }, [b, x, O, k]);
  const S = L.useMemo(() => ({
    reference: A,
    floating: E,
    setReference: w,
    setFloating: v
  }), [w, v]), C = L.useMemo(() => ({
    reference: b,
    floating: x
  }), [b, x]), F = L.useMemo(() => {
    const R = {
      position: n,
      left: 0,
      top: 0
    };
    if (!C.floating)
      return R;
    const Y = Ot(C.floating, m.x), q = Ot(C.floating, m.y);
    return l ? {
      ...R,
      transform: "translate(" + Y + "px, " + q + "px)",
      ...Ft(C.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: Y,
      top: q
    };
  }, [n, l, C.floating, m.x, m.y]);
  return L.useMemo(() => ({
    ...m,
    update: O,
    refs: S,
    elements: C,
    floatingStyles: F
  }), [m, O, S, C, F]);
}
export {
  ye as a,
  we as b,
  ve as c,
  me as f,
  ge as h,
  xe as l,
  he as o,
  pe as s,
  be as u
};
