import { j as Y } from "./jsx-runtime-944c88e2.js";
import { _ as w, $ as j, c as q } from "./index-bf9f2594.js";
import * as X from "react";
import { createElement as d, forwardRef as R, useRef as g, useState as I, useCallback as x, useEffect as y, useMemo as J } from "react";
import { b as Q, a as C } from "./index-5499c00c.js";
import { $ as W } from "./index-61f4edff.js";
import { b as Z, c as ee } from "./index-6b71901e.js";
import { $ as te } from "./index-7ed7ad30.js";
import { $ as F, b as oe, d as ne, a as re } from "./index-62dc40ef.js";
import { $ as B } from "./index-e1c48dc5.js";
import { $ as ae } from "./index-14f22d4f.js";
import { a as ce } from "./index-1ac05127.js";
import { c as se } from "./utils-428888d8.js";
import "./index-760cd071.js";
import "react-dom";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
const [L, Je] = W("Tooltip", [
  F
]), A = F(), ie = "TooltipProvider", le = 700, M = "tooltip.open", [pe, H] = L(ie), de = (t) => {
  const { __scopeTooltip: o, delayDuration: e = le, skipDelayDuration: n = 300, disableHoverableContent: r = !1, children: c } = t, [s, l] = I(!0), a = g(!1), p = g(0);
  return y(() => {
    const i = p.current;
    return () => window.clearTimeout(i);
  }, []), /* @__PURE__ */ d(pe, {
    scope: o,
    isOpenDelayed: s,
    delayDuration: e,
    onOpen: x(() => {
      window.clearTimeout(p.current), l(!1);
    }, []),
    onClose: x(() => {
      window.clearTimeout(p.current), p.current = window.setTimeout(
        () => l(!0),
        n
      );
    }, [
      n
    ]),
    isPointerInTransitRef: a,
    onPointerInTransitChange: x((i) => {
      a.current = i;
    }, []),
    disableHoverableContent: r
  }, c);
}, k = "Tooltip", [ue, O] = L(k), fe = (t) => {
  const { __scopeTooltip: o, children: e, open: n, defaultOpen: r = !1, onOpenChange: c, disableHoverableContent: s, delayDuration: l } = t, a = H(k, t.__scopeTooltip), p = A(o), [i, u] = I(null), v = te(), f = g(0), $ = s ?? a.disableHoverableContent, m = l ?? a.delayDuration, b = g(!1), [T = !1, h] = Q({
    prop: n,
    defaultProp: r,
    onChange: (G) => {
      G ? (a.onOpen(), document.dispatchEvent(new CustomEvent(M))) : a.onClose(), c == null || c(G);
    }
  }), P = J(() => T ? b.current ? "delayed-open" : "instant-open" : "closed", [
    T
  ]), E = x(() => {
    window.clearTimeout(f.current), b.current = !1, h(!0);
  }, [
    h
  ]), _ = x(() => {
    window.clearTimeout(f.current), h(!1);
  }, [
    h
  ]), N = x(() => {
    window.clearTimeout(f.current), f.current = window.setTimeout(() => {
      b.current = !0, h(!0);
    }, m);
  }, [
    m,
    h
  ]);
  return y(() => () => window.clearTimeout(f.current), []), /* @__PURE__ */ d(ne, p, /* @__PURE__ */ d(ue, {
    scope: o,
    contentId: v,
    open: T,
    stateAttribute: P,
    trigger: i,
    onTriggerChange: u,
    onTriggerEnter: x(() => {
      a.isOpenDelayed ? N() : E();
    }, [
      a.isOpenDelayed,
      N,
      E
    ]),
    onTriggerLeave: x(() => {
      $ ? _() : window.clearTimeout(f.current);
    }, [
      _,
      $
    ]),
    onOpen: E,
    onClose: _,
    disableHoverableContent: $
  }, e));
}, S = "TooltipTrigger", $e = /* @__PURE__ */ R((t, o) => {
  const { __scopeTooltip: e, ...n } = t, r = O(S, e), c = H(S, e), s = A(e), l = g(null), a = j(o, l, r.onTriggerChange), p = g(!1), i = g(!1), u = x(
    () => p.current = !1,
    []
  );
  return y(() => () => document.removeEventListener("pointerup", u), [
    u
  ]), /* @__PURE__ */ d(re, w({
    asChild: !0
  }, s), /* @__PURE__ */ d(ae.button, w({
    // We purposefully avoid adding `type=button` here because tooltip triggers are also
    // commonly anchors and the anchor `type` attribute signifies MIME type.
    "aria-describedby": r.open ? r.contentId : void 0,
    "data-state": r.stateAttribute
  }, n, {
    ref: a,
    onPointerMove: C(t.onPointerMove, (v) => {
      v.pointerType !== "touch" && !i.current && !c.isPointerInTransitRef.current && (r.onTriggerEnter(), i.current = !0);
    }),
    onPointerLeave: C(t.onPointerLeave, () => {
      r.onTriggerLeave(), i.current = !1;
    }),
    onPointerDown: C(t.onPointerDown, () => {
      p.current = !0, document.addEventListener("pointerup", u, {
        once: !0
      });
    }),
    onFocus: C(t.onFocus, () => {
      p.current || r.onOpen();
    }),
    onBlur: C(t.onBlur, r.onClose),
    onClick: C(t.onClick, r.onClose)
  })));
}), z = "TooltipPortal", [ve, be] = L(z, {
  forceMount: void 0
}), me = (t) => {
  const { __scopeTooltip: o, forceMount: e, children: n, container: r } = t, c = O(z, o);
  return /* @__PURE__ */ d(ve, {
    scope: o,
    forceMount: e
  }, /* @__PURE__ */ d(B, {
    present: e || c.open
  }, /* @__PURE__ */ d(Z, {
    asChild: !0,
    container: r
  }, n)));
}, D = "TooltipContent", xe = /* @__PURE__ */ R((t, o) => {
  const e = be(D, t.__scopeTooltip), { forceMount: n = e.forceMount, side: r = "top", ...c } = t, s = O(D, t.__scopeTooltip);
  return /* @__PURE__ */ d(B, {
    present: n || s.open
  }, s.disableHoverableContent ? /* @__PURE__ */ d(K, w({
    side: r
  }, c, {
    ref: o
  })) : /* @__PURE__ */ d(he, w({
    side: r
  }, c, {
    ref: o
  })));
}), he = /* @__PURE__ */ R((t, o) => {
  const e = O(D, t.__scopeTooltip), n = H(D, t.__scopeTooltip), r = g(null), c = j(o, r), [s, l] = I(null), { trigger: a, onClose: p } = e, i = r.current, { onPointerInTransitChange: u } = n, v = x(() => {
    l(null), u(!1);
  }, [
    u
  ]), f = x(($, m) => {
    const b = $.currentTarget, T = {
      x: $.clientX,
      y: $.clientY
    }, h = ge(T, b.getBoundingClientRect()), P = ye(T, h), E = Ce(m.getBoundingClientRect()), _ = Pe([
      ...P,
      ...E
    ]);
    l(_), u(!0);
  }, [
    u
  ]);
  return y(() => () => v(), [
    v
  ]), y(() => {
    if (a && i) {
      const $ = (b) => f(b, i), m = (b) => f(b, a);
      return a.addEventListener("pointerleave", $), i.addEventListener("pointerleave", m), () => {
        a.removeEventListener("pointerleave", $), i.removeEventListener("pointerleave", m);
      };
    }
  }, [
    a,
    i,
    f,
    v
  ]), y(() => {
    if (s) {
      const $ = (m) => {
        const b = m.target, T = {
          x: m.clientX,
          y: m.clientY
        }, h = (a == null ? void 0 : a.contains(b)) || (i == null ? void 0 : i.contains(b)), P = !we(T, s);
        h ? v() : P && (v(), p());
      };
      return document.addEventListener("pointermove", $), () => document.removeEventListener("pointermove", $);
    }
  }, [
    a,
    i,
    s,
    p,
    v
  ]), /* @__PURE__ */ d(K, w({}, t, {
    ref: c
  }));
}), [Te, Qe] = L(k, {
  isInside: !1
}), K = /* @__PURE__ */ R((t, o) => {
  const { __scopeTooltip: e, children: n, "aria-label": r, onEscapeKeyDown: c, onPointerDownOutside: s, ...l } = t, a = O(D, e), p = A(e), { onClose: i } = a;
  return y(() => (document.addEventListener(M, i), () => document.removeEventListener(M, i)), [
    i
  ]), y(() => {
    if (a.trigger) {
      const u = (v) => {
        const f = v.target;
        f != null && f.contains(a.trigger) && i();
      };
      return window.addEventListener("scroll", u, {
        capture: !0
      }), () => window.removeEventListener("scroll", u, {
        capture: !0
      });
    }
  }, [
    a.trigger,
    i
  ]), /* @__PURE__ */ d(ee, {
    asChild: !0,
    disableOutsidePointerEvents: !1,
    onEscapeKeyDown: c,
    onPointerDownOutside: s,
    onFocusOutside: (u) => u.preventDefault(),
    onDismiss: i
  }, /* @__PURE__ */ d(oe, w({
    "data-state": a.stateAttribute
  }, p, l, {
    ref: o,
    style: {
      ...l.style,
      "--radix-tooltip-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-tooltip-content-available-width": "var(--radix-popper-available-width)",
      "--radix-tooltip-content-available-height": "var(--radix-popper-available-height)",
      "--radix-tooltip-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-tooltip-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }), /* @__PURE__ */ d(q, null, n), /* @__PURE__ */ d(Te, {
    scope: e,
    isInside: !0
  }, /* @__PURE__ */ d(ce, {
    id: a.contentId,
    role: "tooltip"
  }, r || n))));
});
function ge(t, o) {
  const e = Math.abs(o.top - t.y), n = Math.abs(o.bottom - t.y), r = Math.abs(o.right - t.x), c = Math.abs(o.left - t.x);
  switch (Math.min(e, n, r, c)) {
    case c:
      return "left";
    case r:
      return "right";
    case e:
      return "top";
    case n:
      return "bottom";
    default:
      throw new Error("unreachable");
  }
}
function ye(t, o, e = 5) {
  const n = [];
  switch (o) {
    case "top":
      n.push({
        x: t.x - e,
        y: t.y + e
      }, {
        x: t.x + e,
        y: t.y + e
      });
      break;
    case "bottom":
      n.push({
        x: t.x - e,
        y: t.y - e
      }, {
        x: t.x + e,
        y: t.y - e
      });
      break;
    case "left":
      n.push({
        x: t.x + e,
        y: t.y - e
      }, {
        x: t.x + e,
        y: t.y + e
      });
      break;
    case "right":
      n.push({
        x: t.x - e,
        y: t.y - e
      }, {
        x: t.x - e,
        y: t.y + e
      });
      break;
  }
  return n;
}
function Ce(t) {
  const { top: o, right: e, bottom: n, left: r } = t;
  return [
    {
      x: r,
      y: o
    },
    {
      x: e,
      y: o
    },
    {
      x: e,
      y: n
    },
    {
      x: r,
      y: n
    }
  ];
}
function we(t, o) {
  const { x: e, y: n } = t;
  let r = !1;
  for (let c = 0, s = o.length - 1; c < o.length; s = c++) {
    const l = o[c].x, a = o[c].y, p = o[s].x, i = o[s].y;
    a > n != i > n && e < (p - l) * (n - a) / (i - a) + l && (r = !r);
  }
  return r;
}
function Pe(t) {
  const o = t.slice();
  return o.sort((e, n) => e.x < n.x ? -1 : e.x > n.x ? 1 : e.y < n.y ? -1 : e.y > n.y ? 1 : 0), Ee(o);
}
function Ee(t) {
  if (t.length <= 1)
    return t.slice();
  const o = [];
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    for (; o.length >= 2; ) {
      const c = o[o.length - 1], s = o[o.length - 2];
      if ((c.x - s.x) * (r.y - s.y) >= (c.y - s.y) * (r.x - s.x))
        o.pop();
      else
        break;
    }
    o.push(r);
  }
  o.pop();
  const e = [];
  for (let n = t.length - 1; n >= 0; n--) {
    const r = t[n];
    for (; e.length >= 2; ) {
      const c = e[e.length - 1], s = e[e.length - 2];
      if ((c.x - s.x) * (r.y - s.y) >= (c.y - s.y) * (r.x - s.x))
        e.pop();
      else
        break;
    }
    e.push(r);
  }
  return e.pop(), o.length === 1 && e.length === 1 && o[0].x === e[0].x && o[0].y === e[0].y ? o : o.concat(e);
}
const _e = de, De = fe, Oe = $e, U = xe, Re = _e, Le = De, Me = Oe, Ie = me, V = X.forwardRef(({ className: t, sideOffset: o = 4, ...e }, n) => /* @__PURE__ */ Y.jsx(
  U,
  {
    ref: n,
    sideOffset: o,
    className: se(
      "z-tooltip overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      t
    ),
    ...e
  }
));
V.displayName = U.displayName;
const We = Object.assign(Le, {
  Trigger: Me,
  Content: V,
  Provider: Re,
  Portal: Ie
});
export {
  We as default
};
