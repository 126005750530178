import { $ as R, _ as y } from "./index-bf9f2594.js";
import { forwardRef as O, useContext as W, useState as L, useEffect as b, createElement as D, useRef as h, createContext as X } from "react";
import { a as E, $ as _ } from "./index-5499c00c.js";
import { $ as P, a as j } from "./index-14f22d4f.js";
import { $ as q } from "./index-760cd071.js";
import G from "react-dom";
const m = "dismissableLayer.update", J = "dismissableLayer.pointerDownOutside", M = "dismissableLayer.focusOutside";
let F;
const B = /* @__PURE__ */ X({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), Q = /* @__PURE__ */ O((o, t) => {
  var e;
  const { disableOutsidePointerEvents: n = !1, onEscapeKeyDown: s, onPointerDownOutside: i, onFocusOutside: f, onInteractOutside: d, onDismiss: l, ...v } = o, a = W(B), [c, U] = L(null), u = (e = c == null ? void 0 : c.ownerDocument) !== null && e !== void 0 ? e : globalThis == null ? void 0 : globalThis.document, [, k] = L({}), z = R(
    t,
    (r) => U(r)
  ), g = Array.from(a.layers), [A] = [
    ...a.layersWithOutsidePointerEventsDisabled
  ].slice(-1), N = g.indexOf(A), T = c ? g.indexOf(c) : -1, H = a.layersWithOutsidePointerEventsDisabled.size > 0, w = T >= N, K = Y((r) => {
    const $ = r.target, C = [
      ...a.branches
    ].some(
      (p) => p.contains($)
    );
    !w || C || (i == null || i(r), d == null || d(r), r.defaultPrevented || l == null || l());
  }, u), x = Z((r) => {
    const $ = r.target;
    [
      ...a.branches
    ].some(
      (p) => p.contains($)
    ) || (f == null || f(r), d == null || d(r), r.defaultPrevented || l == null || l());
  }, u);
  return q((r) => {
    T === a.layers.size - 1 && (s == null || s(r), !r.defaultPrevented && l && (r.preventDefault(), l()));
  }, u), b(() => {
    if (c)
      return n && (a.layersWithOutsidePointerEventsDisabled.size === 0 && (F = u.body.style.pointerEvents, u.body.style.pointerEvents = "none"), a.layersWithOutsidePointerEventsDisabled.add(c)), a.layers.add(c), I(), () => {
        n && a.layersWithOutsidePointerEventsDisabled.size === 1 && (u.body.style.pointerEvents = F);
      };
  }, [
    c,
    u,
    n,
    a
  ]), b(() => () => {
    c && (a.layers.delete(c), a.layersWithOutsidePointerEventsDisabled.delete(c), I());
  }, [
    c,
    a
  ]), b(() => {
    const r = () => k({});
    return document.addEventListener(m, r), () => document.removeEventListener(m, r);
  }, []), /* @__PURE__ */ D(P.div, y({}, v, {
    ref: z,
    style: {
      pointerEvents: H ? w ? "auto" : "none" : void 0,
      ...o.style
    },
    onFocusCapture: E(o.onFocusCapture, x.onFocusCapture),
    onBlurCapture: E(o.onBlurCapture, x.onBlurCapture),
    onPointerDownCapture: E(o.onPointerDownCapture, K.onPointerDownCapture)
  }));
}), V = /* @__PURE__ */ O((o, t) => {
  const e = W(B), n = h(null), s = R(t, n);
  return b(() => {
    const i = n.current;
    if (i)
      return e.branches.add(i), () => {
        e.branches.delete(i);
      };
  }, [
    e.branches
  ]), /* @__PURE__ */ D(P.div, y({}, o, {
    ref: s
  }));
});
function Y(o, t = globalThis == null ? void 0 : globalThis.document) {
  const e = _(o), n = h(!1), s = h(() => {
  });
  return b(() => {
    const i = (d) => {
      if (d.target && !n.current) {
        let v = function() {
          S(J, e, l, {
            discrete: !0
          });
        };
        const l = {
          originalEvent: d
        };
        d.pointerType === "touch" ? (t.removeEventListener("click", s.current), s.current = v, t.addEventListener("click", s.current, {
          once: !0
        })) : v();
      }
      n.current = !1;
    }, f = window.setTimeout(() => {
      t.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(f), t.removeEventListener("pointerdown", i), t.removeEventListener("click", s.current);
    };
  }, [
    t,
    e
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function Z(o, t = globalThis == null ? void 0 : globalThis.document) {
  const e = _(o), n = h(!1);
  return b(() => {
    const s = (i) => {
      i.target && !n.current && S(M, e, {
        originalEvent: i
      }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", s), () => t.removeEventListener("focusin", s);
  }, [
    t,
    e
  ]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function I() {
  const o = new CustomEvent(m);
  document.dispatchEvent(o);
}
function S(o, t, e, { discrete: n }) {
  const s = e.originalEvent.target, i = new CustomEvent(o, {
    bubbles: !1,
    cancelable: !0,
    detail: e
  });
  t && s.addEventListener(o, t, {
    once: !0
  }), n ? j(s, i) : s.dispatchEvent(i);
}
const ie = Q, ae = V, ce = /* @__PURE__ */ O((o, t) => {
  var e;
  const { container: n = globalThis == null || (e = globalThis.document) === null || e === void 0 ? void 0 : e.body, ...s } = o;
  return n ? /* @__PURE__ */ G.createPortal(/* @__PURE__ */ D(P.div, y({}, s, {
    ref: t
  })), n) : null;
});
export {
  ae as $,
  ie as a,
  ce as b,
  Q as c
};
