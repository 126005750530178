import { _ as A, $ as k } from "./index-bf9f2594.js";
import { forwardRef as C, createElement as d, useState as _, useRef as we, useEffect as me } from "react";
import { u as ge, a as ue, o as ve, s as xe, f as be, b as Pe, c as ye, h as _e, l as Ae } from "./floating-ui.react-dom-c6515077.js";
import { $ as N } from "./index-14f22d4f.js";
import { $ as Ce, a as M } from "./index-61f4edff.js";
import { $ as Oe } from "./index-5499c00c.js";
import { $ as Se } from "./index-a1ad2cc2.js";
const De = /* @__PURE__ */ C((t, n) => {
  const { children: r, width: o = 10, height: a = 5, ...e } = t;
  return /* @__PURE__ */ d(N.svg, A({}, e, {
    ref: n,
    width: o,
    height: a,
    viewBox: "0 0 30 10",
    preserveAspectRatio: "none"
  }), t.asChild ? r : /* @__PURE__ */ d("polygon", {
    points: "0,0 30,0 15,10"
  }));
}), Ee = De, F = "Popper", [T, Ke] = Ce(F), [He, Z] = T(F), Re = (t) => {
  const { __scopePopper: n, children: r } = t, [o, a] = _(null);
  return /* @__PURE__ */ d(He, {
    scope: n,
    anchor: o,
    onAnchorChange: a
  }, r);
}, We = "PopperAnchor", Ne = /* @__PURE__ */ C((t, n) => {
  const { __scopePopper: r, virtualRef: o, ...a } = t, e = Z(We, r), i = we(null), p = k(n, i);
  return me(() => {
    e.onAnchorChange((o == null ? void 0 : o.current) || i.current);
  }), o ? null : /* @__PURE__ */ d(N.div, A({}, a, {
    ref: p
  }));
}), j = "PopperContent", [Ye, ze] = T(j), Ie = /* @__PURE__ */ C((t, n) => {
  var r, o, a, e, i, p, w, $;
  const { __scopePopper: O, side: c = "bottom", sideOffset: S = 0, align: s = "center", alignOffset: m = 0, arrowPadding: D = 0, collisionBoundary: l = [], collisionPadding: g = 0, sticky: x = "partial", hideWhenDetached: h = !1, avoidCollisions: f = !0, onPlaced: q, ...Y } = t, G = Z(j, O), [E, J] = _(null), K = k(
    n,
    (W) => J(W)
  ), [H, L] = _(null), u = Se(H), Q = (r = u == null ? void 0 : u.width) !== null && r !== void 0 ? r : 0, z = (o = u == null ? void 0 : u.height) !== null && o !== void 0 ? o : 0, V = c + (s !== "center" ? "-" + s : ""), ee = typeof g == "number" ? g : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...g
  }, I = Array.isArray(l) ? l : [
    l
  ], te = I.length > 0, R = {
    padding: ee,
    boundary: I.filter(ke),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: te
  }, { refs: oe, floatingStyles: X, placement: re, isPositioned: b, middlewareData: v } = ge({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: V,
    whileElementsMounted: ue,
    elements: {
      reference: G.anchor
    },
    middleware: [
      ve({
        mainAxis: S + z,
        alignmentAxis: m
      }),
      f && xe({
        mainAxis: !0,
        crossAxis: !1,
        limiter: x === "partial" ? Ae() : void 0,
        ...R
      }),
      f && be({
        ...R
      }),
      Pe({
        ...R,
        apply: ({ elements: W, rects: le, availableWidth: fe, availableHeight: pe }) => {
          const { width: $e, height: he } = le.reference, y = W.floating.style;
          y.setProperty("--radix-popper-available-width", `${fe}px`), y.setProperty("--radix-popper-available-height", `${pe}px`), y.setProperty("--radix-popper-anchor-width", `${$e}px`), y.setProperty("--radix-popper-anchor-height", `${he}px`);
        }
      }),
      H && ye({
        element: H,
        padding: D
      }),
      Fe({
        arrowWidth: Q,
        arrowHeight: z
      }),
      h && _e({
        strategy: "referenceHidden"
      })
    ]
  }), [B, ae] = U(re), P = Oe(q);
  M(() => {
    b && (P == null || P());
  }, [
    b,
    P
  ]);
  const ne = (a = v.arrow) === null || a === void 0 ? void 0 : a.x, ie = (e = v.arrow) === null || e === void 0 ? void 0 : e.y, de = ((i = v.arrow) === null || i === void 0 ? void 0 : i.centerOffset) !== 0, [ce, se] = _();
  return M(() => {
    E && se(window.getComputedStyle(E).zIndex);
  }, [
    E
  ]), /* @__PURE__ */ d("div", {
    ref: oe.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...X,
      transform: b ? X.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: ce,
      "--radix-popper-transform-origin": [
        (p = v.transformOrigin) === null || p === void 0 ? void 0 : p.x,
        (w = v.transformOrigin) === null || w === void 0 ? void 0 : w.y
      ].join(" ")
    },
    dir: t.dir
  }, /* @__PURE__ */ d(Ye, {
    scope: O,
    placedSide: B,
    onArrowChange: L,
    arrowX: ne,
    arrowY: ie,
    shouldHideArrow: de
  }, /* @__PURE__ */ d(N.div, A({
    "data-side": B,
    "data-align": ae
  }, Y, {
    ref: K,
    style: {
      ...Y.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: b ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: ($ = v.hide) !== null && $ !== void 0 && $.referenceHidden ? 0 : void 0
    }
  }))));
}), Xe = "PopperArrow", Be = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, Me = /* @__PURE__ */ C(function(n, r) {
  const { __scopePopper: o, ...a } = n, e = ze(Xe, o), i = Be[e.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ d("span", {
      ref: e.onArrowChange,
      style: {
        position: "absolute",
        left: e.arrowX,
        top: e.arrowY,
        [i]: 0,
        transformOrigin: {
          top: "",
          right: "0 0",
          bottom: "center 0",
          left: "100% 0"
        }[e.placedSide],
        transform: {
          top: "translateY(100%)",
          right: "translateY(50%) rotate(90deg) translateX(-50%)",
          bottom: "rotate(180deg)",
          left: "translateY(50%) rotate(-90deg) translateX(50%)"
        }[e.placedSide],
        visibility: e.shouldHideArrow ? "hidden" : void 0
      }
    }, /* @__PURE__ */ d(Ee, A({}, a, {
      ref: r,
      style: {
        ...a.style,
        // ensures the element can be measured correctly (mostly for if SVG)
        display: "block"
      }
    })))
  );
});
function ke(t) {
  return t !== null;
}
const Fe = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(n) {
    var r, o, a, e, i;
    const { placement: p, rects: w, middlewareData: $ } = n, c = ((r = $.arrow) === null || r === void 0 ? void 0 : r.centerOffset) !== 0, S = c ? 0 : t.arrowWidth, s = c ? 0 : t.arrowHeight, [m, D] = U(p), l = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[D], g = ((o = (a = $.arrow) === null || a === void 0 ? void 0 : a.x) !== null && o !== void 0 ? o : 0) + S / 2, x = ((e = (i = $.arrow) === null || i === void 0 ? void 0 : i.y) !== null && e !== void 0 ? e : 0) + s / 2;
    let h = "", f = "";
    return m === "bottom" ? (h = c ? l : `${g}px`, f = `${-s}px`) : m === "top" ? (h = c ? l : `${g}px`, f = `${w.floating.height + s}px`) : m === "right" ? (h = `${-s}px`, f = c ? l : `${x}px`) : m === "left" && (h = `${w.floating.width + s}px`, f = c ? l : `${x}px`), {
      data: {
        x: h,
        y: f
      }
    };
  }
});
function U(t) {
  const [n, r = "center"] = t.split("-");
  return [
    n,
    r
  ];
}
const Le = Re, Qe = Ne, Ve = Ie, et = Me;
export {
  Ke as $,
  Qe as a,
  Ve as b,
  et as c,
  Le as d
};
